import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CloseButton,
} from "@chakra-ui/react";
import React from "react";
import { useNotifications } from "../providers/NotificationProvider";

export default function Notifications() {
  const { notifications, dismissNotification } = useNotifications();
  if (notifications.length === 0) return null;

  return (
    <>
      {notifications.map((notification, index) => (
        <Alert
          key={notification.id}
          status={notification.type}
          position="fixed"
          top={`${20 + index * 60}px`}
          right="20px"
          zIndex="9999"
          width="400px"
        >
          <AlertIcon />
          <Box>
            <AlertDescription>
              <>{notification.message}</>
            </AlertDescription>
          </Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => {
              dismissNotification(notification.id);
            }}
          />
        </Alert>
      ))}
    </>
  );
}
