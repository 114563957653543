import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Field, Form, Formik, type FormikHelpers } from "formik";
import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { usePostLogin, usePostLoginOAuth } from "../api/auth";
import { useNotifications } from "../providers/NotificationProvider";
import { settings } from "../settings";
import { AxiosError } from "axios";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Bitte geben Sie eine gültige E-Mail ein")
    .required("Bitte geben Sie Ihre E-Mail ein"),
  password: Yup.string().required("Bitte geben Sie Ihr Passwort ein"),
});

interface LoginData {
  email: string;
  password: string;
}

export const Authenticate = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_uri");
  const clientId = searchParams.get("client_id");
  const responseType = searchParams.get("response_type");
  const { mutateAsync: oauthLogin } = usePostLoginOAuth({
    request: { baseURL: settings.API_URL },
  });
  const { mutateAsync: login } = usePostLogin();

  const getOAuthHandler = useCallback(
    (type: string) => async () => {
      try {
        const response = await oauthLogin({ type });
        if (redirectUrl)
          localStorage.setItem(
            "redirect_uri",
            `${settings.API_URL}/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=${responseType}`,
          );
        if (response.data.redirectUrl)
          window.location.href = response.data.redirectUrl;
      } catch (e) {
        console.error(e);
      }
    },
    [oauthLogin, redirectUrl],
  );

  const onSubmitHandler = useCallback(
    async (
      values: LoginData,
      { setFieldValue, resetForm }: FormikHelpers<LoginData>,
    ) => {
      try {
        const result = await login({ data: values });
        if (result.status === 200) {
          addNotification({
            message: "Erfolgreich eingeloggt",
            type: "success",
          });
          window.location.replace(
            `${settings.API_URL}/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=${responseType}`,
          );
        } else {
          throw new Error("Es trat ein Fehler beim Login auf.");
        }
      } catch (err) {
        const status = (err as AxiosError).status;
        if (status === 400) {
          addNotification({
            message: "Die eingegebenen Zugangsdaten sind nicht valide.",
            type: "error",
          });
        }
        if (status && status > 400) {
          addNotification({
            message: "Es trat ein unbekannter Fehler beim Login auf.",
            type: "error",
          });
        }
        resetForm();
        setFieldValue("email", values.email);
      }
    },
    [login, redirectUrl, addNotification, navigate],
  );

  if (!redirectUrl) {
    return (
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Redirection missing
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          You cannot use the authentication. Please open the application you
          want to use to authenticate.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <Text fontSize="2xl" textAlign="center" mb={6}>
        Login
      </Text>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <VStack spacing={4}>
              <FormControl isInvalid={Boolean(errors.email) && touched.email}>
                <Field
                  as={Input}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="E-Mail"
                  autoComplete="email"
                />
                {errors.email && touched.email && (
                  <Text color="red.500" fontSize="sm">
                    {errors.email}
                  </Text>
                )}
              </FormControl>
              <FormControl
                isInvalid={Boolean(errors.password) && touched.password}
              >
                <Field
                  as={Input}
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Passwort"
                  autoComplete="current-password"
                />
                {errors.password && touched.password && (
                  <Text color="red.500" fontSize="sm">
                    {errors.password}
                  </Text>
                )}
              </FormControl>
              <Button colorScheme="red" type="submit" isLoading={isSubmitting}>
                Anmelden
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
      <VStack mt={3} spacing={2} w="100%">
        <Text>oder</Text>
        <VStack mt={1} w="100%">
          {settings.OAUTH_TYPES.map((oauthType) => (
            <Button
              w="100%"
              key={oauthType.type}
              onClick={getOAuthHandler(oauthType.type)}
            >
              <Image ml={-2} src={`${oauthType.icon}`} width={14} />
              Login via {oauthType.name}
            </Button>
          ))}
        </VStack>
      </VStack>
    </>
  );
};
